<template>
	<w-dialog v-model="showDialog" :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'" :title="$t('documents.clean_trash')" @close="close">
		<v-layout>
			{{ $t('documents.clean_trash_message') }}
		</v-layout>
		<template v-slot:actions>
			<v-spacer />
			<w-btn flat :loading="loading" @click="doValidate()">{{ $t('actions.yes') }}</w-btn>
			<w-btn flat @click="close">{{ $t('actions.no') }}</w-btn>
		</template>
	</w-dialog>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'EmptyTrashDialog',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		folder: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			loading: false,
			showDialog: true
		}
	},
	destroyed: function () {
		this.close()
	},
	methods: {
		close: function () {
			this.showDialog = false
			this.$emit('close')
		},
		doValidate: function () {
			this.loading = true
			this.service
				.deleteFolder(this.vendorId, this.folder)
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('documents.cleaned_trash_message'))
				})
				.finally(() => {
					this.loading = false
					this.close()
				})
		}
	}
}
</script>
